import config from "../config.json";
import React, { useState, useEffect } from 'react';
import Headers from "../bin/Headers";
import { useNavigate } from 'react-router-dom';
import abbrInt from "../bin/abbrInt";
import dontRerender from "../bin/dontRerender";
import LOADING from "../components/LOADING";
import CANDLESTICKCHART from '../components/CANDLESTICKCHART';
import STOCK_NOTIONAL_ECHART from "../components/STOCK_NOTIONAL_ECHART";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import { Link } from 'react-router-dom';

const MAIN = (props) => {
	let token = props.token,
		ticker = props.ticker,
		open = props.open,
		high = props.high,
		low = props.low,
		close = props.close,
		volume = props.volume,
		stockNotional = props.stockNotional,
		[candles, setCandles] = useState(null),
		[LoginRequired, setLoginRequired] = useState(false),
		[LoadingValue, setLoadingValue] = useState(true);
	
	async function start() {
		if (!token) { setLoginRequired(true); return; }
		if (!ticker || !token) { return; }
		setLoadingValue(true);
		let data = await fetch(`${config.api}/capitalflow?ticker=${ticker}`, { headers: Headers(token) }).then(d => d.json()).then(d => d ? d : null);
		if (!data || !data.length) { 
			setLoadingValue(false);
			return;
		}
		// let chartData = data.reverse();
		let chartData = data;
		//check last candle for live data
		if (stockNotional && !isNaN(stockNotional) && chartData && chartData.length && chartData[chartData.length - 1].StockNotional === null) {
			chartData[chartData.length - 1].StockNotional = stockNotional;
		}
		// console.log(chartData);
		setCandles(chartData);
		setLoadingValue(false);
	}

	function renderUpdate() {
		if (!candles || !candles.length) { return; }
		const updatedCandles = [...candles];
		updatedCandles[updatedCandles.length - 1] = {
			...updatedCandles[updatedCandles.length - 1],
			Open: open,
			High: high,
			Low: low,
			Close: close,
			Volume: volume,
			StockNotional: stockNotional,
		};
		// console.log(updatedCandles);
		setCandles(updatedCandles);
	}

	useEffect(() => { start(); }, [ticker]);
	// useEffect(() => { renderUpdate(); }, [ticker, open, high, low, close, volume, stockNotional]);
	
	return (
    <div className="rounded-lg flex flex-col justify-start items-center bg-gray-500 border border-solid border-gray-500 w-full">
			<div className="bg-gradient-to-r from-sky-950 to-purple-950 w-full flex flex-row h-full min-h-24 items-center justify-center">
				{LoginRequired === true && <div className="h-100 flex items-center justify-center py-8 text-center text-gray-300">
					<Link to="/login" className="text-indigo-100 rounded bg-indigo-700 px-4 py-1 border border-gray-500">Login Required</Link>
				</div>}
				{!LoginRequired && LoadingValue === true && <div className="h-100 flex items-center justify-center py-8 text-center text-gray-300"><LOADING color="text-green-500" /></div>}
				{!LoginRequired && LoadingValue === false && <div className="h-100 flex items-center justify-center py-8 text-center text-gray-300 w-full">
					<STOCK_NOTIONAL_ECHART candles={candles} open={open} high={high} low={low} close={close} volume={volume} />
				</div>}
			</div>
		</div>
  );
};

export default React.memo(MAIN, dontRerender);