import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import config from "../config.json";
import _logout from "../bin/logout";
import dontRerender from "../bin/dontRerender";
import todaysDate from "../bin/todaysDate";
import decodeJwt from "../bin/decodeJwt";
import authorizedLevel from "../bin/authorizedLevel";
import determineTradingDayOfWeek from "../bin/determineTradingDayOfWeek";
import determineMarketOpenTimestamp from "../bin/determineMarketOpenTimestamp";
import determineMarketCloseTimestamp from "../bin/determineMarketCloseTimestamp";
import Headers from '../bin/Headers';
import currentTime from '../bin/currentTime';
import setMetaTags from '../bin/setMetaTags';
import formatStockName from "../bin/formatStockName";
import MODAL from "../components/MODAL";
import TICKER_INPUT_WITH_INFO_BUTTON from "../components/TICKER_INPUT_WITH_INFO_BUTTON";
import TICKER_IMMEDIATE_DATA_WITH_VOLUME_MODAL from "../components/TICKER_IMMEDIATE_DATA_WITH_VOLUME_MODAL";
import ORDER_BOOK from "../components/ORDER_BOOK";
import TRADE_LOG from "../components/TRADE_LOG";
import NOTABLE_TRADES from "../components/NOTABLE_TRADES";
// import OPTION_OPEN_INTEREST from "../components/OPTION_OPEN_INTEREST";
import OPTION_OPEN_INTEREST from "../components/_OPTION_OPEN_INTEREST";
import OPTIONS_LARGEST_TRADES from "../components/OPTIONS_LARGEST_TRADES";
import STOCKS_LARGEST_TRADES from "../components/STOCKS_LARGEST_TRADES";
import TICKER_CHART from "../components/TICKER_CHART_CHARTD3_V2";
import LOADING from "../components/LOADING";
import POSITIONS from "../components/POSITIONS";
import { useWebSocket } from '../components/WEBSOCKET_CONTEXT';
import unzipWebsocketMessage from '../bin/unzipWebsocketMessage';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

const MAIN = (props) => {
	const token = useSelector(state => state.token);
	let { symbol } = useParams();
	symbol = symbol ? symbol.toUpperCase() : null;
	
		// let { updateLiveTickers } = useWebSocket();
		let { websocket } = useWebSocket();

	let [ticker, updateTicker] = useState(symbol || ''),
		[marketHolidayNotice, setMarketHolidayNotice] = useState(null),
		marketStatusChecked = useRef(null),
		marketHoliday = useRef(false),
		focusedTab = useRef(null),
		[showDelayedRefresh, setShowDelayedRefresh] = useState(false),
		[showDelayedData, setShowDelayedData] = useState(false),
		[delayedData, setDelayedData] = useState(false),
		[tickerStats, updateTickerStats] = useState(null),
		[lastTrade, updateLastTrade] = useState(null),
		[showTickerInfo, updateShowTickerInfo] = useState(false),
		[tickerDetails, updateTickerDetails] = useState(null),
		[topTrades, updateTopTrades] = useState(null),
		[optionDetails, updateOptionDetails] = useState(null),
		[optionOpenInterestValue, updateOptionOpenInterestValue] = useState(null),
		[liveTrades, setLiveTrades] = useState([]),
		[refreshing, setRefreshing] = useState(false),
		[timerTillRefresh, setTimerTillRefresh] = useState(0),
		[market, setMarket] = useState('closed'),
		[marketState, setMarketState] = useState(null),
		[orderBook, updateOrderBook] = useState(null),
		[loading, setLoading] = useState(true),
		[loadStockNotionalChart, setLoadStockNotionalChart] = useState(false),
		[loadOptionNotionalChart, setLoadOptionNotionalChart] = useState(false),
		intervalRef = useRef(null),
		marketCheck = useRef(null),
		internalLoading = useRef(false),
		optionContractsMappingByTicker = useRef(null),
		marketOpen = useRef(null),
		watchingLiveList = useRef({}),
		initialTickerData = useRef({}),
		decodedToken = decodeJwt(token),
		urlQueryParams = new URLSearchParams(window.location.search); //,
      // logoutUser = _logout();
      // logoutUser = _logout;
  
	const loadTickerData = async (_ticker) => {
		if (urlQueryParams && urlQueryParams.get('date')) {
			let uri = `/_/${(_ticker).toUpperCase()}?date=${urlQueryParams.get('date')}`;
			window.location.href = uri;
			return;
		}
    let uri = `/_/${(_ticker).toUpperCase()}`;
		window.location.href = uri;
		return;
  };

	const loadTickerDetails = async (initialLoad = false) => {
		if (!ticker) { return; }
		// let _tickerDetails = await fetch(`https://${token ? `io.redstripedtie.com/ursa` : `api.redstripedtie.com`}/tickerdata?ticker=${ticker}${urlQueryParams && urlQueryParams.get('date') ? `&date=${urlQueryParams.get('date')}` : ``}`, { headers: Headers(token) }).then(x => x.json()).catch(e => { console.log(e); internalLoading.current = false; return null; });
		let _tickerDetails = await fetch(`https://io.redstripedtie.com/ursa/tickerdata?ticker=${ticker}${urlQueryParams && urlQueryParams.get('date') ? `&date=${urlQueryParams.get('date')}` : ``}`, { headers: Headers(token) }).then(x => x && x!=='Request timed out' ? x.json() : null).catch(e => { console.log(e); internalLoading.current = false; return null; });
		// let _tickerDetails = await fetch(`https://api.redstripedtie.com/tickerdata?ticker=${ticker}${urlQueryParams && urlQueryParams.get('date') ? `&date=${urlQueryParams.get('date')}` : ``}`, { headers: Headers(token) }).then(x => x.json()).catch(e => { console.log(e); internalLoading.current = false; return null; });
		if (_tickerDetails && ((_tickerDetails.error && _tickerDetails.error === 'Too many requests. Please try again later.') || (_tickerDetails.message && _tickerDetails.message==='nope'))) {
			window.location = `/unavailable?message=${encodeURIComponent(`Too many requests. Please try again later.`)}`;
			return;
		}
		// let _tickerDetails = await fetch(`https://api.redstripedtie.com/tickerdata?ticker=${ticker}`, { headers: Headers(token) }).then(x => x.json()).catch(e => { console.log(e); internalLoading.current = false; return null; });
		if (_tickerDetails && _tickerDetails.error && _tickerDetails.error === 'invalid token') {
			// return logoutUser(token);
			return _logout(token);
		}
		if (!_tickerDetails || !_tickerDetails.result || !_tickerDetails.result) { return; }
		let isCurrentDay = _tickerDetails && _tickerDetails.result && _tickerDetails.result.IndexDate && todaysDate() === _tickerDetails.result.IndexDate ? true : false;
		// let tradeDate = _tickerDetails && _tickerDetails.result && _tickerDetails.result.IndexDate && todaysDate() !== _tickerDetails.result.IndexDate ? _tickerDetails.result.IndexDate : null;
		let tradeDate = _tickerDetails && _tickerDetails.result && _tickerDetails.result.TradeLast && _tickerDetails.result.TradeLast.EntryDateString && todaysDate() !== _tickerDetails.result.TradeLast.EntryDateString ? _tickerDetails.result.TradeLast.EntryDateString : null;
		if (!isCurrentDay) {
			setDelayedData(tradeDate);
			marketOpen.current = false;
			setMarket('closed');
		}
		initialTickerData.current = _tickerDetails.result;
		updateLastTrade(_tickerDetails.result.TradeLast);
		updateTickerDetails(_tickerDetails.result);

		//update the webpage title
		setMetaTags({ title: `${ticker.toUpperCase()}${_tickerDetails && _tickerDetails.result && _tickerDetails.result.Name ? ` - ${formatStockName(_tickerDetails.result.Name)}` : ``}` });
		
		if (_tickerDetails.result.OptionContracts) {
			if (initialLoad) { loadOpenInterestValue(); }
		}
	};
	
	const loadTickerStats = async (initialLoad = false) => {
    if (!ticker){ return; }
    let _tickerStats= await fetch(`https://api.redstripedtie.com/tickerstats?ticker=${ticker}${urlQueryParams && urlQueryParams.get('date') ? `&date=${urlQueryParams.get('date')}` : ``}`, {headers:Headers(token)}).then((d) => (d.status === 200 ? d.json() : null)).then((d) => (d && d.result ? d.result : null));
    // let _tickerStats= await fetch(`https://api.redstripedtie.com/tickerstats?ticker=${ticker}`, {headers:Headers(token)}).then((d) => (d.status === 200 ? d.json() : null)).then((d) => (d && d.result ? d.result : null));
    if (!_tickerStats){ return; }
    updateTickerStats(_tickerStats);
  };
  
	// const loadOptionDetails = async () => {
	// 	if (!ticker) { return; }
  //   let _optionData = await fetch(`https://io.redstripedtie.com/ursa/ticker?ticker=${ticker}_OPTIONS`, {headers:Headers(token)}).then( x => x.json() ).then( x => x.result ? x.result : null);
	// 	if (!_optionData) { return; }
  //   updateOptionDetails(_optionData);
  // };
	
	const loadOpenInterestValue = async () => {
		if (!ticker) { return; }
    let _openInterestValue = await fetch(`https://api.redstripedtie.com/openinterestvalue?ticker=${ticker}${urlQueryParams && urlQueryParams.get('date') ? `&date=${urlQueryParams.get('date')}` : ``}`, {headers:Headers(token)}).then( x => x.json() ).then( x => x.result ? x.result : null);
    // let _openInterestValue = await fetch(`https://api.redstripedtie.com/openinterestvalue?ticker=${ticker}`, {headers:Headers(token)}).then( x => x.json() ).then( x => x.result ? x.result : null);
		if (!_openInterestValue) { return; }
    updateOptionOpenInterestValue(_openInterestValue);
  };
	
	const loadTopTrades = async () => {
		if (!ticker) { return; }
    let _topTrades = await fetch(`https://api.redstripedtie.com/toptrades?ticker=${ticker}${urlQueryParams && urlQueryParams.get('date') ? `&date=${urlQueryParams.get('date')}` : ``}`, {headers:Headers(token)}).then( x => x.json() ).then( x => x.result ? x.result : null);
    // let _topTrades = await fetch(`https://api.redstripedtie.com/toptrades?ticker=${ticker}`, {headers:Headers(token)}).then( x => x.json() ).then( x => x.result ? x.result : null);
		if (!_topTrades) { return; }
    updateTopTrades(_topTrades);
  };
  
  const pullLastStockTrades = async() => {
    if (!ticker){ return; }
		let _lastTrades = await fetch(`${config.api}/trades?ticker=${ticker}`, { headers: Headers(token) }).then(d => d.json());
		if (!_lastTrades || !_lastTrades.result || !_lastTrades.result){ return; }
    // addTradesToTable(_lastTrades.result);
    setLiveTrades(_lastTrades.result);
	};
	
  const addTradesToTable = (data)=>{
    setLiveTrades( prevData => {
      let truncatedLiveTrades=[];
      if (data.length){
        truncatedLiveTrades= [...data, ...prevData];
      }else{
        truncatedLiveTrades= [data, ...prevData];
        updateImmediateData(data);
      }
      if ( truncatedLiveTrades.length>100 ){
        truncatedLiveTrades.length=100;
      }
      return truncatedLiveTrades;
    });
	};
	
  const updateImmediateData = (data) => {
    updateLastTrade({s:data.qyt, p:data.price});
  };
	
	const checkMarket = async () => {
		// console.log('checking market');
		let currentUtcDateString = todaysDate();
		let currentTime = (new Date()).getTime();
		let tradingDayOfWeek = determineTradingDayOfWeek(currentUtcDateString);
		let timeOfMarketOpen = determineMarketOpenTimestamp(currentUtcDateString);
		let timeOfMarketPreOpen = timeOfMarketOpen + (5 * 60 * 60 * 1000 * -1) + (30 * 60 * 1000 * -1);
		let timeOfMarketClosed = determineMarketCloseTimestamp(currentUtcDateString);
		let timeOfMarketPostClosed = timeOfMarketClosed + (4 * 60 * 60 * 1000);

		// console.log("currentTime: ", currentTime);
		// console.log("currentUtcDateString: ", currentUtcDateString);
		// console.log("tradingDayOfWeek: ", tradingDayOfWeek);
		// console.log("timeOfMarketOpen: ", timeOfMarketOpen);
		// console.log("timeOfMarketPreOpen: ", timeOfMarketPreOpen);
		// console.log("timeOfMarketClosed: ", timeOfMarketClosed);
		// console.log("timeOfMarketPostClosed: ", timeOfMarketPostClosed);

		if (!tradingDayOfWeek || currentTime <= timeOfMarketPreOpen || currentTime >= timeOfMarketPostClosed) {
			// console.log('closed');
			marketOpen.current = false;
			return;
		}

		let _market = 'closed';
		if (timeOfMarketPreOpen <= currentTime && timeOfMarketOpen >= currentTime) { _market = 'pre'; }
		else if (timeOfMarketOpen <= currentTime && timeOfMarketClosed >= currentTime) { _market = 'open';}
		else if (timeOfMarketClosed <= currentTime && timeOfMarketPostClosed >= currentTime) { _market = 'post'; }

		if (marketStatusChecked.current === null && _market !== 'closed') {
			let _marketStatus = await fetch(`https://io.redstripedtie.com/ursa/marketholiday`, { headers: Headers(token) }).then(d => d.json()).then(d => d && d.message==='ok' ? d.result : false).catch(e => { console.log(e); return null; });
			// let _marketStatus = await fetch(`https://${token ? `io.redstripedtie.com/ursa` : `api.redstripedtie.com`}/marketholiday`, { headers: Headers(token) }).then(d => d.json()).then(d => d && d.message==='ok' ? d.result : false).catch(e => { console.log(e); return null; });
			// let _marketStatus = await fetch(`${config.api}/marketholiday`, { headers: Headers(token) }).then(d => d.json()).then(d => d && d.message==='ok' ? d.result : false).catch(e => { console.log(e); return null; });
			marketStatusChecked.current = true;
			if (_marketStatus) {
				if (_marketStatus.closed) {
					marketHoliday.current = true;
				}
				setMarketHolidayNotice(_marketStatus);
			}
		}

		// visitor view, but live and limited refresh for GME
		if (!token && _market !== 'closed' && ticker === 'GME') {
			setShowDelayedRefresh(true);
			setShowDelayedData(false);
		} else if (!token && _market !== 'closed' && ticker !== 'GME') {
			setShowDelayedRefresh(false);
			setShowDelayedData(true);
		}

		if (!marketHoliday.current) {
			marketOpen.current = true;
		}
		if (marketHoliday.current === true) {
			setMarket('closed');
			return;
		}
		if (_market !== market) { setMarket(_market); }
		return;
	};

	const liveLoadData = async () => {
		if (!ticker) { return; }
		if (!websocket || !websocket.readyState || websocket.readyState !== WebSocket.OPEN) { return; }
		// websocket.send(JSON.stringify({ request: 'livetickers', liveTickers: [ticker] }));
		// websocket.send(JSON.stringify({ request: 'livetickers', liveTickers: [] }));
		websocket.send(JSON.stringify({ request: 'orderbook', payload:ticker }));
		websocket.onmessage = async (message) => {
			try {
				// let data = JSON.parse(e.data);
				// if (data.OrderBook) {
				// 	updateOrderBook(data.OrderBook);
				// }
				// if (!data || !data.ticker || data.ticker !== ticker) { return; }
				
				const decompressedData = await unzipWebsocketMessage(message.data);
				const reply = JSON.parse(decompressedData);
				if (!reply.response) { return; }
				if (reply.response === 'orderbook') {
					//[CurrentTimestamp, Ticker, TotalOrders, MidPrice, OrderBookWeight, OrderBookShareDifference, OrderBookShareDifferencePercent,LastPrice, LastSize, RunningVolume, FilterRangeBound, PositiveThreshold, NegativeThreshold, BidSideShareNotional, BidSideLargestPrice, BidSideLargestOrder, BidOrderNBBO, BidExchange, BidPrice, BidSize, BidValue, BidSideShares, BidSideShareNotionalBounds, BidSideSharesBounds, AskSideShareNotional, AskSideLargestPrice, AskSideLargestOrder, AskOrderNBBO, AskExchange, AskPrice, AskSize, AskValue, AskSideShares, AskSideShareNotionalBounds, AskSideSharesBounds, BidSideOrders, AskSideOrders]
					let payload = reply.payload;
					// if (!payload?.length || payload.length !== 37) { console.log(`[Order Book Error] Expected an array of 37 elements.\n`, payload); return; }
					if (!payload?.length || payload.length !== 37) { return; }
					let _payload = {
						CurrentTimestamp: payload[0],
						Ticker: payload[1],
						TotalOrders: payload[2],
						MidPrice: payload[3],
						OrderBookWeight: payload[4],
						OrderBookShareDifference: payload[5],
						OrderBookShareDifferencePercent: payload[6],
						LastPrice: payload[7],
						LastSize: payload[8],
						RunningVolume: payload[9],
						FilterRangeBound: payload[10],
						PositiveThreshold: payload[11],
						NegativeThreshold: payload[12],
						BidSideShareNotional: payload[13],
						BidSideLargestPrice: payload[14],
						BidSideLargestOrder: payload[15],
						BidOrderNBBO: payload[16],
						BidExchange: payload[17],
						BidPrice: payload[18],
						BidSize: payload[19],
						BidValue: payload[20],
						BidSideShares: payload[21],
						BidSideShareNotionalBounds: payload[22],
						BidSideSharesBounds: payload[23],
						AskSideShareNotional: payload[24],
						AskSideLargestPrice: payload[25],
						AskSideLargestOrder: payload[26],
						AskOrderNBBO: payload[27],
						AskExchange: payload[28],
						AskPrice: payload[29],
						AskSize: payload[30],
						AskValue: payload[31],
						AskSideShares: payload[32],
						AskSideShareNotionalBounds: payload[33],
						AskSideSharesBounds: payload[34],
						BidSideOrders: payload[35],
						AskSideOrders: payload[36],
					};
					// console.log(_payload);
					updateOrderBook(_payload);
					return;
				}

				// if (reply.response === 'orderbook') {
				// 	updateOrderBook(reply.payload);
				// }
				
				// let updatedData = { ...initialTickerData.current };
				// if (data.update) {
				// 	updatedData = { ...updatedData, ...data.update };
				// 	if (data.update.TradeLast) { updateLastTrade(data.update.TradeLast); }
				// }
				// clearInterval(intervalRef.current);
				// updateTickerDetails(updatedData);
			} catch (e) {
				console.log('error parsing websocket data');
			}
		};
	};

	const unwindLiveData = async () => {
		if (!websocket || !websocket.readyState || websocket.readyState !== WebSocket.OPEN) { return; }
		// websocket.send(JSON.stringify({ request: 'livetickers', liveTickers: [] }));
		websocket.send(JSON.stringify({ request: 'orderbook', payload: null }));
	};

  const loadData = async () => {
    // if (!token){ return; }
		if (!ticker) { return; }
		internalLoading.current = true;
		setLoading(true);
		setRefreshing(true);
    updateLastTrade(null);
    updateTickerDetails(null);
    updateTickerStats(null);
    watchingLiveList.current = {};
		optionContractsMappingByTicker.current = {};
		// updateLiveTickers([ticker]);
		await Promise.all([loadTickerDetails(true), loadTickerStats(), loadTopTrades()]);
		setLoading(false);
		setRefreshing(false);
		internalLoading.current = false;
    return;
	};

	const reloadData = async () => {
		if (!token && ticker !== 'GME') { return; }
		//if the current path is not /_/${ticker}, then clear the interval
		if ((window.location.pathname).includes(`/_/${ticker}`)===false) {
			clearInterval(intervalRef.current);
			clearInterval(marketCheck.current);
			return;
		}
		// if (!token){ return; }
		if (!ticker) { return; }
		if (internalLoading.current === true) { return; }
		if (marketOpen.current === false || marketOpen.current === null) { return; }
		internalLoading.current = true;
		setRefreshing(true);
		await Promise.all([ loadTickerDetails() ]);
		setLoading(false);
		setRefreshing(false);
		internalLoading.current = false;
		countdownToRefresh();
	};

	const countdownToRefresh = () => {
		let countdown = 60;
		let interval = setInterval(() => {
			countdown--;
			setTimerTillRefresh(`${countdown}s`);
			if (countdown <= 0) {
				clearInterval(interval);
			}
		}, 1000);
	};

	const handleVisibilityChange = () => {
		if (document.hidden) {
			clearInterval(intervalRef.current);
			clearInterval(marketCheck.current);
		} else {
			if (token) {
				intervalRef.current = setInterval(reloadData, 1000); //refresh every second
			} else {
				intervalRef.current = setInterval(reloadData, 60000); //refresh every minute
			}
		}
	};

	useEffect(() => {
		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);


	useEffect(() => {
		liveLoadData();
		return () => {
			unwindLiveData();
		}
	}, [websocket]);
	

	useEffect(() => {
		if (ticker !== symbol) { updateTicker(symbol); }
		loadData();
		checkMarket();
		countdownToRefresh();
		if (token) {
			intervalRef.current = setInterval(reloadData, 1000); //refresh every second
		} else {
			intervalRef.current = setInterval(reloadData, 60000); //refresh every minute
		}
		marketCheck.current = setInterval(checkMarket, 1000);
		return () => {
			// setMetaTags({ title: 'Red Striped Tie' });
			clearInterval(intervalRef.current);
			clearInterval(marketCheck.current);
			// unwindLiveData();
		};
	}, [ticker, symbol]);


	function determineLastForChart(tickerDetails) {
		let last = null;
		let market = null;
		if (!tickerDetails || (!tickerDetails.hasOwnProperty('TradeLastExtended') && !tickerDetails.hasOwnProperty('TradeLast'))) { return last; }
		if (!tickerDetails.TradeLast) {
			if (tickerDetails.TradeLastExtended.Market === 'PRE') { market = 'PRE'; }
		} else {
			if (tickerDetails.TradeLastExtended.Market === 'POST') { market = 'POST'; }
			else { market = 'OPEN'; }
		}
		if (market === 'OPEN') {
			last = tickerDetails.TradeLast.Price;
		} else {
			last = tickerDetails.TradeLastExtended.Price;
		}
		return last;
	}

  return (
		<div className={`w-full my-2 flex gap-4 flex-wrap justify-between items-start text-white ${marketHolidayNotice || showDelayedRefresh || showDelayedData ? 'pb-10' : ''}`}>
			{loading ? <div className='w-full h-24 flex items-center justify-center'><LOADING /></div> : <></>}
			{!loading ?
				// <div className='flex gap-5 justify-center xl:justify-between w-full flex flex-wrap flex-col sm:flex-row'>
				<div className='flex gap-5 justify-center xl:justify-between w-full flex flex-wrap flex-row'>
					<div className='w-full sm:w-auto'>
						<TICKER_INPUT_WITH_INFO_BUTTON ticker={ticker} loadTickerData={loadTickerData} token={token} showTickerInfo={showTickerInfo} updateShowTickerInfo={updateShowTickerInfo} />
						{delayedData ? <Link to="/pricing" className='rounded text-xs text-amber-400 font-light text-center px-2 py-1 bg-rose-950/50 border border-gray-800 w-full block'>Trade Data from {delayedData}</Link> : <></>}
						<TICKER_IMMEDIATE_DATA_WITH_VOLUME_MODAL
							tickerDetails={tickerDetails}
							tickerStats={tickerStats}
							lastTrade={lastTrade}
							showTickerInfo={showTickerInfo}
							optionOpenInterestValue={optionOpenInterestValue}
							market={market}
							delayedData={delayedData}
							showStockNotionalChart={setLoadStockNotionalChart}
							showOptionNotionalChart={setLoadOptionNotionalChart}
						/>
						{/*decodedToken && authorizedLevel((decodedToken && decodedToken.level ? decodedToken.level : null), 'diamond') ?
							<div>
								<POSITIONS />
							</div>
							: <></>
						*/}
					</div>


					<OPTION_OPEN_INTEREST
						ticker={ticker}
						topTrades={topTrades}
						tickerStats={tickerStats}
						stockNotional={tickerDetails && tickerDetails.StockNotional ? tickerDetails.StockNotional : null}
						optionVolume={tickerDetails && tickerDetails.OptionVolume ? tickerDetails.OptionVolume : null}
						token={token}
						orderBook={orderBook}
						focusedTab={focusedTab}
						optionStrikeVolume={tickerDetails && tickerDetails.OptionStrikeVolume ? tickerDetails.OptionStrikeVolume : null}
						lastTrade={tickerDetails && tickerDetails.Change ? tickerDetails.Change : null}
						bid={tickerDetails && tickerDetails.Bid ? tickerDetails.Bid : null}
						ask={tickerDetails && tickerDetails.Ask ? tickerDetails.Ask : null}
						open={tickerDetails && tickerDetails.hasOwnProperty('TradeOpen') && tickerDetails.TradeOpen!==null && tickerDetails.TradeOpen.Price!==null ? tickerDetails.TradeOpen.Price : null}
						close={tickerDetails && tickerDetails.hasOwnProperty('TradeClose') && tickerDetails.TradeClose!==null && tickerDetails.TradeClose.Price!==null ? tickerDetails.TradeClose.Price : null}
						high={tickerDetails && tickerDetails.hasOwnProperty('TradeHigh') && tickerDetails.TradeHigh!==null && tickerDetails.TradeHigh.Price!==null ? tickerDetails.TradeHigh.Price : (tickerDetails && tickerDetails.hasOwnProperty('TradeHighExtended') && tickerDetails.TradeHighExtended!==null && tickerDetails.TradeHighExtended.Price!==null ? tickerDetails.TradeHighExtended.Price : null)}
						low={tickerDetails && tickerDetails.hasOwnProperty('TradeLow') && tickerDetails.TradeLow!==null && tickerDetails.TradeLow.Price!==null ? tickerDetails.TradeLow.Price : (tickerDetails && tickerDetails.hasOwnProperty('TradeLowExtended') && tickerDetails.TradeLowExtended!==null && tickerDetails.TradeLowExtended.Price!==null ? tickerDetails.TradeLowExtended.Price : null)}
						// last={tickerDetails && tickerDetails.hasOwnProperty('TradeLast') && tickerDetails.TradeLast!==null && tickerDetails.TradeLast.Price!==null ? tickerDetails.TradeLast.Price : (tickerDetails && tickerDetails.hasOwnProperty('TradeLastExtended') && tickerDetails.TradeLastExtended!==null && tickerDetails.TradeLastExtended.Price!==null ? tickerDetails.TradeLastExtended.Price : null)}
						last={determineLastForChart(tickerDetails)}
						range={tickerDetails && tickerDetails.Range ? tickerDetails.Range : null}
						volume={tickerDetails && tickerDetails.Volume ? tickerDetails.Volume : null}
						latestOptionTrades={tickerDetails && tickerDetails.LatestOptionTrades ? tickerDetails.LatestOptionTrades : null}
						latestStockTrades={tickerDetails && tickerDetails.LatestStockTrades ? tickerDetails.LatestStockTrades : null}
						notableTrades={tickerDetails && tickerDetails.NotableTrades ? tickerDetails.NotableTrades : null}
						topStockTradesByValue={tickerDetails && tickerDetails.TopStockTradesByValue ? tickerDetails.TopStockTradesByValue : null}
						topOptionTradeByValue={tickerDetails && tickerDetails.TopOptionTradeByValue ? tickerDetails.TopOptionTradeByValue : null}
						topOptionTradeBySize={tickerDetails && tickerDetails.TopOptionTradeBySize ? tickerDetails.TopOptionTradeBySize : null}
						topOptionBinaryTradeByValue={tickerDetails && tickerDetails.TopOptionBinaryTradeByValue ? tickerDetails.TopOptionBinaryTradeByValue : null}
						optionSnapshot={tickerDetails && tickerDetails.OptionSnapshot ? tickerDetails.OptionSnapshot : null}
						optionExpirations={tickerDetails && tickerDetails.OptionExpirations ? tickerDetails.OptionExpirations : null}
						optionContracts={tickerDetails && tickerDetails.OptionContracts ? tickerDetails.OptionContracts : null}
						showStockNotionalChart={loadStockNotionalChart}
						setLoadStockNotionalChart={setLoadStockNotionalChart}
						showOptionNotionalChart={loadOptionNotionalChart}
						setLoadOptionNotionalChart={setLoadOptionNotionalChart}
					/>

				</div>
				: <></>}

			{/* {tickerDetails && tickerDetails.OptionContracts ? <OPTION_OPEN_INTEREST ticker={ticker} token={token} lastTrade={tickerDetails && tickerDetails.Change ? tickerDetails.Change : null} high={tickerDetails && tickerDetails.High ? tickerDetails.High : null} low={tickerDetails && tickerDetails.Low ? tickerDetails.Low : null} optionDetails={optionDetails} /> : <></>} */}
			{/* <OPTION_OPEN_INTEREST ticker={ticker} token={token} lastTrade={tickerDetails && tickerDetails.Change ? tickerDetails.Change : null} high={tickerDetails && tickerDetails.High ? tickerDetails.High : null} low={tickerDetails && tickerDetails.Low ? tickerDetails.Low : null} optionDetails={optionDetails} optionContracts={tickerDetails && tickerDetails.OptionContracts ? tickerDetails.OptionContracts : null} /> */}
			{/* <STOCKS_LARGEST_TRADES ticker={ticker} trades={tickerDetails && tickerDetails.TopStockTradesByValue ? Object.values(tickerDetails.TopStockTradesByValue) : null} timestamp={tickerDetails && tickerDetails.Updated ? tickerDetails.Updated : null} loading={loading} /> */}
			{/* <TRADE_LOG ticker={ticker} liveTrades={liveTrades} marketState={marketState} /> */}
			{/* <TICKER_CHART ticker={ticker} token={token} /> */}

			{marketHolidayNotice ? <div className="fixed inset-x-0 bottom-0">
				<div className="flex items-center gap-x-6 bg-amber-500 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
					<p className="text-sm leading-6 text-white text-center w-full">
						Market {marketHolidayNotice.closed ? `is closed` : (marketHolidayNotice.earlyClose ? 'will close early' : '') } for {marketHolidayNotice.holiday}
					</p>
				</div>
			</div> : <></>}
			{!marketHolidayNotice && showDelayedRefresh ? <div className="fixed inset-x-0 bottom-0">
				<div className="flex items-center gap-x-6 bg-amber-500 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
					<p className="text-sm leading-6 text-white ">
						Data automatically refreshes every 60 seconds in visitor view {refreshing ? <LOADING inline={true} text={false} /> : (timerTillRefresh ? <span className='rounded px-2 py-0.5 bg-amber-700'>{timerTillRefresh}</span> : <></>)}
					</p>
					<div className="flex flex-1 justify-end">
						{/* <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
							<span className="sr-only">Dismiss</span>
							<InformationCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />
						</button> */}
					</div>
				</div>
				{/* <MODAL headline='Fix for now' information='In order to keep up with the traffic spike, (and not go under from the cloud costs), rate limiting is being implemented until solution can be deployed' open={true} setOpen={() => { false; }} /> */}
			</div> : <></>}
			{!marketHolidayNotice && showDelayedData ? <div className="fixed inset-x-0 bottom-0">
				<div className="flex items-center gap-x-6 bg-amber-500 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
					<p className="text-sm leading-6 text-white text-justify">
						Live data and real time notifications is available for subscribers.
					</p>
					<div className="flex flex-1 justify-end">
						<Link to="/pricing" className='rounded px-2 py-0.5 bg-sky-700'>Subscribe</Link>
					</div>
				</div>
				{/* <MODAL headline='Fix for now' information='In order to keep up with the traffic spike, (and not go under from the cloud costs), rate limiting is being implemented until solution can be deployed' open={true} setOpen={() => { false; }} /> */}
			</div> : <></>}
			
    </div>
  );
}

export default React.memo(MAIN, dontRerender);