import React, { createContext, useContext, useRef, useState } from 'react';
import config from '../config.json';
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from 'react-redux';
// import actions from '../state/actions';


const WebSocketContext = createContext({
  websocket: null,
	updateWebSocket: () => { },
	closeWebSocket: () => { },
});

export const WebSocketProvider = ({ children }) => {
	// const dispatch = useDispatch(),
		// navigate = useNavigate(),
		// heartbeat = useRef(null),
	const attemptingReconnection = useRef(false);
	const [websocket, setWebsocket] = useState(null);

	const updateWebSocket = (token) => {
		// if (token === null || token === undefined) { return; }
		if (websocket && websocket.readyState && websocket.readyState === WebSocket.OPEN) {
			closeWebSocket();  // Ensure previous session is properly closed
			// return;
		}

		let query = [];
		if (token) { query.push(`token=${token}`); }
		query.push(`gzip=true`);
		query = query && query.length ? `?${query.join('&')}` : ``;

		// const ws = new WebSocket(`${config.wsapi}/${token ? `?token=${token}` : ''}`);
		const ws = new WebSocket(`${config.wsapi}/${query}`);
		ws.binaryType = "arraybuffer";
		ws.onopen = () => {
			setWebsocket(ws);
			// heartbeat.current = setInterval(() => { ws.send(JSON.stringify({ request: 'heartbeat' })); }, 5000);
			// ws.send(JSON.stringify({ request:"test" })); //testing
			attemptingReconnection.current = false;
		};
		ws.onclose = () => {
			setWebsocket(null);
			// clearInterval(heartbeat.current);
			attemptingReconnection.current = true;
		};
		ws.onerror = (e) => {
			console.log('websocket error', e);
			setWebsocket(null);
			// clearInterval(heartbeat.current);
			//attempt to reconnect as long as it's not already trying to reconnect
			if (!attemptingReconnection.current) {
				attemptingReconnection.current = true;
				return updateWebSocket(token);
			}
			// dispatch(actions.removeToken(token)); //remove the token from the store
			// navigate('/login', { replace: true });
		};
		//testing
		// ws.onmessage = async (message) => {
		// 	try {
		// 		console.log(message.data);
		// 		const decompressedData = await unzipWebsocketMessage(message.data);
		// 		const jsonResponse = JSON.parse(decompressedData);
		// 		console.log(jsonResponse);
		// 	} catch (err) {
		// 		console.error("Failed to decompress WebSocket response:", err);
		// 	}
		// };
	};

	const closeWebSocket = () => {
		if (websocket) {
			websocket.close();
			setWebsocket(null); // Ensure it's reset
		}
	};

  return (
    <WebSocketContext.Provider value={{ websocket, updateWebSocket, closeWebSocket }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);