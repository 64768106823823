import React, {useEffect, useState, useRef} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import todaysDate from "../bin/todaysDate";
import config from '../config.json';
import decodeJwt from "../bin/decodeJwt";
import epochConverterToEST from "../bin/epochConverterToEST";
import wait from "../bin/wait";
import dontRerender from "../bin/dontRerender";
import abbrInt from "../bin/abbrInt";
import Headers from "../bin/Headers";
import compileTradeDetailForModal from "../bin/compileTradeDetailForModal";
import LOADING from "../components/LOADING";
import SPARKLINECHART from "../components/SPARKLINECHART";
import ECHARTNOTIONAL from "../components/ECHARTNOTIONAL";
import MODAL from "../components/MODAL";
import { BanknotesIcon, BookOpenIcon, ClockIcon, BeakerIcon, ChevronDownIcon, XMarkIcon, StarIcon } from "@heroicons/react/24/outline";

const MAIN= (props)=>{
	let token = useSelector(state => state.token),
		ticker = props.ticker,
		open = props.open,
		high = props.high,
		low = props.low,
		close = props.close,
		last = props.last,
		volume = props.volume,
		orderBook = props.orderBook,
		orderBookTime = props.orderBookTime,
		tickerStats = props.tickerStats,
		passedtrades = props.trades,
		_trades = useRef([]),
		_candles = useRef([]),
		_averageLargeTrade = useRef(0),
		_openTrade = useRef(0),
		_sort = useRef('time'),
		loading = props.loading,
		[orderBookUnavailable, updateOrderBookUnavailable] = useState(true),
		[showPriceLevels, setShowPriceLevels] = useState(false),
		[showOrderBook, setShowOrderBook] = useState(false),
		[loadingCandles, setLoadingCandles] = useState(false),
		[delayedData, setDelayedData] = useState(false),
		[sort, setSort] = useState('time'),
		[trades, setTrades] = useState([]),
		[candles, updateCandles]= useState([]),
		[showTradeInfo, updateShowTradeInfo]= useState(false),
		[focusedTrade, updateFocusedTrade]= useState(null),
		[tradeDetail, updateTradeDetail]= useState(null);

	const tradeEntryUi = (item, index) => {
		let side = null;
		let bgColor = `bg-gray-950${item.Market !== 'OPEN' && !item.ClosingTrade && !item.OpeningTrade ? '/50' : ''}`;
		let overAverage = item.Notional > 0 && (_averageLargeTrade.current) && _averageLargeTrade.current > 0 && item.Notional >= (_averageLargeTrade.current) ? true : false;
		let overOpenAndNotClose = _openTrade.current && item.Notional >= _openTrade.current && !item.OpeningTrade && !item.ClosingTrade ? true : false;
		
		// if (item.TradeSide === 'SELL' && !item.ClosingTrade && !item.OpeningTrade) { side = 'Bid'; bgColor = 'bg-rose-950/50'; }
		// else if (item.TradeSide === 'BUY' && !item.ClosingTrade && !item.OpeningTrade) { side = 'Ask'; bgColor = 'bg-emerald-950/50'; }
		if (item.TradeSide === 'SELL') { side = 'Bid'; bgColor = `bg-rose-950${item.Market!=='OPEN' && !item.ClosingTrade && !item.OpeningTrade ? '/50' : ''}`; }
		else if (item.TradeSide === 'BUY') { side = 'Ask'; bgColor = `bg-emerald-950${item.Market!=='OPEN' && !item.ClosingTrade && !item.OpeningTrade ? '/50' : ''}`; }
		else if (item.TradeSide) { side = 'Mid'; }

		let exchange = item.Exchange ? item.Exchange : null;
		if (exchange === 'XADF') { exchange = 'DARK'; }
		else if (exchange === 'XNYS') { exchange = 'NYSE'; }
		else if (exchange === 'XNAS') { exchange = 'NASDAQ'; }
		// else if (exchange === 'ARCX') { exchange = 'NYSE ARCA'; }
		else if (exchange === 'ARCX') { exchange = 'ARCA'; }
		else if (exchange === 'BATS') { exchange = 'BATS'; }
		// else if (exchange === 'XCHI') { exchange = 'NYSE CHICAGO'; }
		else if (exchange === 'XCHI') { exchange = 'CHICAGO'; }
		// else if (exchange === 'XPHL') { exchange = 'NASDAQ PHILLY'; }
		else if (exchange === 'XPHL') { exchange = 'PHILLY' }
		// else if (exchange === 'XASE') { exchange = 'NYSE AMEX'; }
		else if (exchange === 'XASE') { exchange = 'AMEX'; }
		else if (exchange === 'XOTC') { exchange = 'OTC'; }
		else if (exchange === 'XCBO') { exchange = 'CBOE'; }
		else if (exchange === 'IEXG') { exchange = 'IEX'; }
		else if (exchange === 'MEMX') { exchange = 'MEMX'; }

		// console.log(`item [${side}]: `, item);
		return (
			// <button key={index} className={`flex flex-row w-full text-xxs sm:text-xs text-gray-200 ${bgColor}`} onClick={()=>modalTradeDetail(item)}>
			<button key={item.TradeKey} className={`flex flex-row w-full text-xxs sm:text-xs text-gray-200 ${bgColor} ${focusedTrade===null ? 'block' : (focusedTrade && focusedTrade.TradeKey && focusedTrade.TradeKey===item.TradeKey ? 'block' : 'hidden')}`} onClick={() => {
				//if already focused, then pop open the detail
				if (focusedTrade && focusedTrade.TradeKey && focusedTrade.TradeKey === item.TradeKey) {
					modalTradeDetail(item);
				}
				updateSorting('graph');
				updateFocusedTrade(item);
			}}>
				{/* <span className={`${!ticker ? 'hidden' : 'flex'} ${overOpenAndAverage ? 'animate-pulse' : ''} p-1 items-center justify-center font-light w-12 ${item.Rank>=1 && item.Rank<=3 ? 'text-amber-500' : ''}`}>#{item.Rank}</span> */}
				<span className={`${!ticker ? 'hidden' : 'flex'} p-1 items-center justify-center font-light w-12 ${overAverage ? 'text-amber-500' : ''}`}>#{item.Rank}</span>
				<span className={`hidden p-1 items-center justify-center w-20 ${!ticker ? 'sm:flex' : ''}`}>{item.Ticker}</span>
				<span className={`p-1 flex items-center justify-start sm:justify-center flex-grow `}>
					<span className='hidden sm:block'>{(item.Size).toLocaleString()} @ ${(item.Price).toLocaleString()}</span>
					<span className='flex flex-col w-full items-start justify-start sm:hidden'>

						<span className='flex flex-row w-full items-start justify-start gap-1'>
							<span className={`text-left font-bold ${!ticker ? 'inline' : 'hidden'} w-14`}>{item.Ticker}</span>
						</span>
						<span className={`flex flex-row w-full items-start justify-between gap-1`}>
							<span className="inline-block text-left w-full">
								{(item.Size).toLocaleString()} @ ${(Number((item.Price).toFixed(2))).toFixed(2).toLocaleString()}
							</span>
							<span className="inline-block text-right w-full">
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Value:</span>
								<span className={`ml-1 font-light w-100 text-center ${overOpenAndNotClose ? 'text-amber-500' : ''}`}>${abbrInt(item.Notional)}</span>
							</span>
						</span>
						<span className="flex flex-row w-full items-start justify-between gap-1">
							<span className="inline-block text-left w-full">
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Side:</span>
								{item.ClosingTrade ? <span className='mx-1 text-xxs px-1 bg-rose-700 rounded text-rose-200'>close</span> : <></>}
								{item.OpeningTrade ? <span className='mx-1 text-xxs px-1 bg-emerald-700 rounded text-emerald-200'>open</span> : <></>}
								{!item.ClosingTrade && !item.OpeningTrade ? <span className="ml-1 font-light w-100 text-center text-blue-400">{item.Side ? item.Side : (side ? side : '')}</span> : <></>}
							</span>
							<span className={`inline-block text-left w-full ${item.Exchange==='XADF' ? 'bg-gray-900/50' : ''}`}>
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Exchange:</span>
								<span className="ml-1 font-light w-100 text-center text-rose-400">{exchange}</span>
							</span>
							<span className="inline-block text-right w-full">
								<span className='font-light text-gray-400 w-100 text-left text-[8px]'>Time:</span>
								<span className={`ml-1 font-light w-100 text-center ${item.Audited ? 'text-cyan-200' : 'text-gray-400'}`}>{item.EntryTimeString}</span>
							</span>
						</span>
					</span>
				</span>
				<span className={`hidden sm:flex p-1 items-center justify-center w-32 ${overOpenAndNotClose ? 'text-amber-500' : ''}`}>${abbrInt(item.Notional, false)}</span>
				<span className="hidden sm:flex p-1 items-center justify-center flex-col w-20">
					{!item.ClosingTrade && !item.OpeningTrade ? <span>{item.Side ? item.Side : (side ? side : '')}</span> : <></>}
					{item.ClosingTrade ? <span className='mx-1 text-xxs px-1 bg-rose-700 rounded text-rose-200'>close</span> : <></>}
					{item.OpeningTrade ? <span className='mx-1 text-xxs px-1 bg-emerald-700 rounded text-emerald-200'>open</span> : <></>}
				</span>
				<span className={`hidden sm:flex p-1 items-center justify-center w-24 ${item.Exchange==='XADF' ? 'bg-gray-900/50' : 'text-amber-300'}`}>{exchange}</span>
				<span className={`hidden sm:flex p-1 items-center justify-end text-xxs w-24 ${item.Audited ? 'text-cyan-200' : ''}`}>{item.EntryTimeString}</span>
			</button>
		);
	};

	const sortTrades = () => {
		if (!_trades.current || !_trades.current.length) { return; }
		let _tradesArray = [..._trades.current];
		let __sort = _sort.current;
		_tradesArray.sort((a, b) => b.Notional - a.Notional);
		_tradesArray = _tradesArray.filter((entry) => typeof entry==='object').map((entry, index) => { entry.Rank = (index + 1); return entry; });
		if (__sort === 'time') {
			_tradesArray.sort((a, b) => {
				const timeA = Number(a.EntryTimestamp);
        const timeB = Number(b.EntryTimestamp);

        // Check if both timestamps are valid numbers
        if (!isNaN(timeA) && !isNaN(timeB)) {
					return timeB - timeA;
        } else {
					// Handle invalid timestamps here (e.g., return 0 or some default value)
					return 0;
        }
			});
		}
		if (__sort === 'value') {
			_tradesArray.sort((a, b) => b.Notional - a.Notional);
		}
		_trades.current = _tradesArray;
		setTrades(_trades.current);
	};
	
	const modalTradeDetail = (trade) => {
		if (!trade) { return; }
		// console.log(trade);
		let exchange = trade.Exchange ? trade.Exchange : null;
		if (exchange === 'XADF') { exchange = 'DARK POOL'; }
		else if (exchange === 'XNYS') { exchange = 'NYSE'; }
		else if (exchange === 'XNAS') { exchange = 'NASDAQ'; }
		else if (exchange === 'ARCX') { exchange = 'NYSE ARCA'; }
		else if (exchange === 'BATS') { exchange = 'BATS'; }
		else if (exchange === 'XCHI') { exchange = 'NYSE CHICAGO'; }
		else if (exchange === 'XPHL') { exchange = 'NASDAQ PHILLY'; }
		else if (exchange === 'XASE') { exchange = 'NYSE AMEX'; }
		else if (exchange === 'XOTC') { exchange = 'OTC'; }
		else if (exchange === 'XCBO') { exchange = 'CBOE'; }
		else if (exchange === 'IEXG') { exchange = 'IEX'; }
		else if (exchange === 'MEMX') { exchange = 'MEMX'; }
		let beforeQuoteTime = null;
		let afterQuoteTime = null;
		if (trade.QuoteTimestampBeforeTrade) {
			beforeQuoteTime = epochConverterToEST(trade.QuoteTimestampBeforeTrade);
			beforeQuoteTime = `${beforeQuoteTime.Hour}:${beforeQuoteTime.Minute}:${beforeQuoteTime.Second}.${beforeQuoteTime.Millisecond}`;
		}
		if (trade.QuoteTimestampAfterTrade) {
			afterQuoteTime = epochConverterToEST(trade.QuoteTimestampAfterTrade);
			afterQuoteTime = `${afterQuoteTime.Hour}:${afterQuoteTime.Minute}:${afterQuoteTime.Second}.${afterQuoteTime.Millisecond}`;
		}
		let _tradeDetail = compileTradeDetailForModal({
			ticker: trade.ticker ? trade.ticker : null,
			name: trade.name ? trade.name : null,
			time: trade.EntryTimestamp ? trade.EntryTimestamp : null,
			qyt: trade.Size ? trade.Size : null,
			price: trade.Price ? trade.Price : null,
			value: trade.Notional ? trade.Notional : null,
			ex: exchange ? exchange : null,
			side: trade.OrderSide ? trade.OrderSide : null,
			flags: trade.Conditions ? trade.Conditions : null,
			beforeQuoteTime,
			bidBeforeTrade: trade.BidBeforeTrade ? trade.BidBeforeTrade : null,
			midBeforeTrade: trade.MidBeforeTrade ? trade.MidBeforeTrade : null,
			askBeforeTrade: trade.AskBeforeTrade ? trade.AskBeforeTrade : null,
			afterQuoteTime,
			bidAfterTrade: trade.BidAfterTrade ? trade.BidAfterTrade : null,
			midAfterTrade: trade.MidAfterTrade ? trade.MidAfterTrade : null,
			askAfterTrade: trade.AskAfterTrade ? trade.AskAfterTrade : null,
		});
    updateTradeDetail(_tradeDetail);
    updateShowTradeInfo(true);
  };

	const updateSorting = (sorting) => {
		setSort(sorting);
		_sort.current = sorting;
		sortTrades();
	};

	const checkIfDelayedData = () => {
		let tradeDate = passedtrades && passedtrades.length ? passedtrades[0].EntryDateString : null;
		// console.log(tradeDate);
		if (tradeDate && tradeDate !== todaysDate()) {
			setDelayedData(tradeDate);
		}
		// if (!token) { setDelayedData(true); return; }
		// // if (!token) { return; }
		// //check the level if red
		// let { level } = decodeJwt(token);
		// if (level==='red' && todaysDate()!==indexDate) {
		// 	setDelayedData(true);
		// }	
	};

	const readCandlesP3 = async (latestTradeDate) => {
		setLoadingCandles(true);
		const response = await fetch(`https://io.redstripedtie.com/ursa/candles_p3?ticker=${ticker}&format=minute&startDate=${latestTradeDate ? latestTradeDate : todaysDate()}&endDate=${latestTradeDate ? latestTradeDate : todaysDate()}`, { headers: Headers(token) }).then(d => d.json());
		if (!response || response.message !== 'ok' || !response.result) { setLoadingCandles(false); return; }
		setLoadingCandles(false);
		
		// for (let i = 0; i < response.result.length; i++){
		// 	let candles = (response.result).slice(0, i);
		// 	updateCandles(candles);
		// 	await wait(10);
		// }
		// const placeholderCount = Math.max(50 - candles.length, 0);
		// let filledCandles = new Array(placeholderCount).fill({
		// 	Close: null,
		// 	DateString: null,
		// 	High: null,
		// 	Low: null,
		// 	Market: null,
		// 	Open: null,
		// 	TimeString: null,
		// 	Trades: null,
		// 	Volume: null
		// });
		// candles = [candles, ...filledCandles];
		// updateCandles(candles);
		
		_candles.current = response.result;
		updateCandles(_candles.current);
	};

	useEffect(() => {
		checkIfDelayedData();
		_trades.current = passedtrades;
		let latestTradeDate = trades && trades.length ? trades[0].EntryDateString : null;
		if (!candles.length && latestTradeDate) {
			readCandlesP3(latestTradeDate);
		}
		sortTrades();

		if (!_trades.current || !_trades.current.length) { return; }
		let sum = 0;
		let tradeCount = _trades.current.length;
		let openTrade = 0;
		for (let trade of _trades.current) {
			sum += trade.Notional && !isNaN(trade.Notional) ? trade.Notional : 0;
			if (trade.OpeningTrade) { openTrade = trade.Notional; }
		}
		_averageLargeTrade.current = sum > 0 ? Math.trunc(sum/tradeCount) : 0;
		_openTrade.current = openTrade ? openTrade : 0;
	}, [passedtrades, sort]);

	useEffect(() => { if (orderBook) { updateOrderBookUnavailable(false); } }, [orderBook]);

	// useEffect(() => {
		// if (!_candles.current || !_candles.current.length) { return; }
		// let newCandles = [..._candles.current];
		// newCandles[newCandles.length - 1].Close = last;
		// _candles.current = newCandles;
		// console.log(_candles.current);
		// updateCandles(_candles.current);
		// console.log(open, high, low, close, last, volume);
		// console.log(_trades.current);
	// }, [open, high, low, close, last, volume]);

  return (
		<div className={`w-full h-full flex flex-col rounded-tr-md rounded-md border bg-gray-200 text-black`}>
			
			<div className="border-gray-300 rounded-t-lg w-full text-center text-semibold flex flex-col justify-center items-start px-1 py-0.5">
				<span className='flex flex-row items-center justify-end w-full'>
					{delayedData ?
						<Link to="/pricing" className='flex flex-row items-start justify-start'>
							<span className='text-xs text-rose-600'>Trades from {delayedData}</span>
						</Link>
						: <></>}
					{!loading ?
						<span className="flex-col inline-flex items-end justify-end flex-grow sm:items-center gap-1">
							<span className='inline-flex w-full justify-end items-center gap-1'>
								<button
									type="button"
									onClick={() => { updateFocusedTrade(null); updateSorting(sort === 'value' ? 'time' : 'value'); }}
									className={`${focusedTrade!==null ? 'hidden' : ''} rounded px-2 py-0.5 text-xs font-semibold border ${sort === 'value' ? 'border-gray-700 text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
									<BanknotesIcon className='w-5 h-4' />
								</button>
								<button
									type="button"
									onClick={() => { updateFocusedTrade(null); updateSorting(sort === 'time' ? 'value' : 'time'); }}
									className={`${focusedTrade!==null ? 'hidden' : ''} rounded px-2 py-0.5 text-xs font-semibold border ${sort === 'time' ? 'border-gray-700 text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
									<ClockIcon className='w-5 h-4' />
								</button>
								<button
									type="button"
									onClick={() => { updateFocusedTrade(null); updateSorting('time'); }}
									className={`${focusedTrade===null ? 'hidden' : ''} rounded px-2 py-0.5 text-xs font-semibold border border-gray-700 text-white bg-rose-600`}>
									<XMarkIcon className='w-5 h-4' />
								</button>
							</span>
							{/* <span className='text-xs text-sky-800'>{ orderBookMatching ? 'Order Book Matching' : 'NBBO Matching'}</span> */}
						</span>
						:
						<></>
					}
				</span>
			</div>
			
			<div className="col-span-2 flex flex-grow flex-col rounded-br-md rounded-bl-md border bg-gray-700">
				{loading ? <div className='h-48 flex items-center justify-center'><LOADING color="text-green-500" /></div> : <></>}
				{!loading && (!trades || !trades.length) ? <h6 className='my-5 text-gray-400 text-center'>No Stock Trades</h6> : <></>}
				{/* {!loading && sort!=='graph' && trades && trades.length ? */}
				{!loading && trades && trades.length ?
					<div className={`${focusedTrade===null ? 'h-full' : ''} w-full`}>
						<div className='flex flex-row w-full text-xxs sm:text-xs text-gray-200'>
							<span className={`p-1 items-center justify-center border uppercase font-medium tracking-wider w-12 ${!ticker ? 'hidden' : 'flex'}`}>Rank</span>
							<span className={`hidden p-1 items-center justify-center border font-medium uppercase tracking-wider w-20 ${!ticker ? 'sm:flex' : ''}`}>Ticker</span>
							<span className="p-1 flex items-center justify-center border font-medium uppercase tracking-wider flex-grow">Trade</span>
							<span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-32">Value{sort === 'value' ? <ChevronDownIcon className='w-5 h-4' /> : <></>}</span>
							<span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-20">Side</span>
							<span className="hidden sm:flex p-1 items-center justify-center border font-medium uppercase tracking-wider w-24">Exchange</span>
							<span className="hidden sm:flex p-1 items-center justify-end border font-medium uppercase tracking-wider w-24">Time{sort === 'time' ? <ChevronDownIcon className='w-5 h-4' /> : <></>}</span>
						</div>
						
						{trades.map((item, index) => tradeEntryUi(item, index))}
					</div>
					: <></>
				}
				{/* {!loading && sort === 'graph' && trades && trades.length && candles && candles.length ? */}
				{!loading && sort === 'graph' && trades && trades.length ?
					<div className="bg-gradient-to-r from-sky-950 to-purple-950 w-full flex flex-col max-h-[280px] sm:max-h-full h-full min-h-24 items-center justify-center">
						<ECHARTNOTIONAL
							candles={candles}
							last={last}
							timing="minute"
							marker={focusedTrade}
							loading={loadingCandles}
							orderBook={orderBook}
							showOrderBook={showOrderBook}
							tickerStats={tickerStats}
							showPriceLevels={showPriceLevels} />
					</div>
					: <></>
				}

				{!loading && sort === 'graph' && trades && trades.length ?
					<div className='border bg-gray-200 text-black w-full flex flex-row items-center justify-between rounded-b-md'>
						<div className='flex flex-row gap-0.5 items-center w-full justify-start'>
							<button
								type="button"
								onClick={() => setShowPriceLevels(!showPriceLevels)}
								className={`rounded px-2 py-0.5 text-xs font-semibold border ${showPriceLevels ? 'border-white text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
								<span className='flex sm:hidden'>$ Levels</span>
								<span className='hidden sm:flex'>Price Levels</span>
							</button>
							<button
								type="button"
								disabled={orderBookUnavailable}
								onClick={() => setShowOrderBook(!showOrderBook)}
								className={`rounded px-2 py-0.5 text-xs font-semibold border ${showOrderBook ? 'border-white text-white bg-emerald-600' : (!orderBookUnavailable ? 'border-gray-500 text-gray-300 bg-slate-700' : 'border-gray-500/50 text-gray-300/50 bg-slate-700/50')}`}>
								<span className='flex sm:hidden'>Orders</span>
								<span className='hidden sm:flex'>Order Book</span>
							</button>
						</div>

						<div className={`flex flex-row gap-0.5 items-center w-full justify-end ${focusedTrade!==null ? '' : 'hidden'}`}>
							<button
								type="button"
								onClick={() => { modalTradeDetail(focusedTrade); }}
								className={`rounded px-2 py-0.5 text-xs font-semibold border border-gray-500 text-gray-300 bg-slate-700`}>
								Trade Detail
							</button>
							{/* <button
								type="button"
								onClick={() => { modalTradeDetail(focusedTrade); }}
								className={`${focusedTrade!==null ? '' : 'hidden'} rounded px-2 py-0.5 text-xs font-semibold border border-gray-500 text-gray-300 bg-slate-700`}>
								<StarIcon className='w-5 h-4' />
							</button> */}
						</div>
					</div>
				: <></>}
				
			</div>

			<MODAL
        open={ showTradeInfo }
        setOpen={() => { updateShowTradeInfo(false); updateTradeDetail(null); }}
        headline="Trade Detail [stock]"
        html_information={ true }
        information={ tradeDetail } />

		</div>
  );
};

// export default MAIN;
export default React.memo(MAIN, dontRerender);